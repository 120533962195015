<template>
  <router-layout>
    <div class="page">
      <div class="header">
        <h2>{{title}}</h2>
        <div class="timeBox">
          <div>
            <span class="iconfont icon-shizhong"></span>
            <span>{{create_time}}</span>
          </div>
          <div class="like">
            <van-checkbox v-model="favorite" @click="favoriteChange">
              <template #icon="props">
                <van-icon name="like" color="#f03535" v-if="props.checked" />
                <van-icon name="like-o" v-else />
              </template>
            </van-checkbox>
            <div v-if="favorite">已收藏</div>
            <div v-else>收藏</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="body">
        <!-- House -->
        <ul v-show="HouseShow">
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            价格:
            <span>{{money}}元</span>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
        <!-- HouseA -->
        <ul v-show="HouseAShow">
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            租金:
            <span>{{money}}<span v-show="hasMoney">元/平米/天</span></span>
          </li>
          <li>
            面积:
            <span>{{area}} 平米</span>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li>
            身份:
            <span>{{identity}}</span>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
        <!-- HouseB -->
        <ul v-show="HouseBShow">
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            租金:
            <span>{{money}}<span v-show="hasMoney">元/月</span></span>
          </li>
          <li>
            房型:
            <span>{{room_type}}</span>
          </li>
          <li>
            出租形式:
            <span>{{rental_form}}</span>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li>
            身份:
            <span>{{identity}}</span>
          </li>
          <li class="fl">
            <div class="spanfl">房屋配置:</div>
            <div class="spanfr">
              <span v-show="bed.value">{{bed.text}}</span>
              <span v-show="wardrobe.value">{{wardrobe.text}}</span>
              <span v-show="sofa.value">{{sofa.text}}</span>
              <span v-show="tv.value">{{tv.text}}</span>
              <span v-show="refrigerator.value">{{refrigerator.text}}</span>
              <span v-show="washing_machine.value">{{washing_machine.text}}</span>
              <span v-show="air_conditioning.value">{{air_conditioning.text}}</span>
              <span v-show="hot_water.value">{{hot_water.text}}</span>
              <span v-show="wifi.value">{{wifi.text}}</span>
              <span v-show="heating.value">{{heating.text}}</span>
            </div>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
        <!-- HouseC -->
        <ul v-show="HouseCShow">
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            价格:
            <span>{{money}}<span v-show="hasMoney">元</span></span>
          </li>
          <li>
            面积:
            <span>{{area}} 平米</span>
          </li>
          <li>
            房型:
            <span>{{room_type}}</span>
          </li>
          <li>
            装修:
            <span>{{decoration}}</span>
          </li>
          <li>
            楼层:
            <span>{{floor}}层</span>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li>
            身份:
            <span>{{identity}}</span>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
        <!-- HouseD -->
        <ul v-show="HouseDShow">
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            价格:
            <span>{{money}}<span v-show="hasMoney">元</span></span>
          </li>
          <li>
            面积:
            <span>{{area}} 平米</span>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li>
            身份:
            <span>{{identity}}</span>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
      </div>
      <div class="dr">详细描述</div>
      <div class="detail" v-html="description"></div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      hasMoney:true,
      infoType: "",
      id: "",
      tid: "",
      title: "",
      create_time: "",
      name: "",
      phone: "",
      valid_time: "",
      description: "",
      favorite: false, //收藏
      image: [],
      money: "",
      area: "", //面积
      identity: "", //身份
      rental_form: "", //房型
      room_type: "", //出租形式
      decoration: "", //装修
      floor: "", //楼层
      hasImg: false,
      bed: {
        text: "床",
        value: 0
      },
      wardrobe: {
        text: "衣柜",
        value: 0
      },
      sofa: {
        text: "沙发",
        value: 0
      },
      tv: {
        text: "电视",
        value: 0
      },
      refrigerator: {
        text: "冰箱",
        value: 0
      },
      washing_machine: {
        text: "洗衣机",
        value: 0
      },
      air_conditioning: {
        text: "空调",
        value: 0
      },
      hot_water: {
        text: "热水器",
        value: 0
      },
      wifi: {
        text: "宽带",
        value: 0
      },
      heating: {
        text: "暖气",
        value: 0
      },
      HouseShow: false,
      HouseAShow: false,
      HouseBShow: false,
      HouseCShow: false,
      HouseDShow: false
    };
  },
  mixins: [authMixin],
  created() {
    this.id = this.$route.query.id;
    this.infoType = this.$route.query.infoType;
    this.getDetail();
    this.getHistory()
  },
  methods: {
    getHistory() {
      //我的历史
      this.$request({
        method: "post",
        url: "/cms/history/",
        data: {
          type: this.infoType,
          fid: this.id
        }
      }).then(res => {
        console.log(res);
      });
    },
    favoriteChange() {
      if (!this.favorite) {
        console.log("去取消");
        //取消收藏
        this.$request({
          method: "post",
          url: "/cms/deleteFavorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      } else {
        //收藏
        console.log("去收藏");
        this.$request({
          method: "post",
          url: "/cms/favorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      }
    },
    imageView(index) {
      let _this = this;
      ImagePreview({
        images: this.image,
        startPosition: index
      });
    },
    getDetail() {
      this.$request({
        method: "get",
        url: "/cms/house/detail/" + this.id
      }).then(res => {
        console.log(res);
        if (res.data) {
          this.create_time = res.data.create_time;
          this.title = res.data.title;
          this.name = res.data.name;
          this.phone = res.data.phone;
          this.description = res.data.description;
          if (res.data.money == 0) {
            this.money = "面议";
            this.hasMoney = false;
          } else {
            this.money = res.data.money.toFixed(2);
          }
          this.area = res.data.area;
          this.floor = res.data.floor;
          this.favorite = res.data.favorite;
          if (res.data.imgs) {
            this.image = res.data.imgs.split(",");
            this.hasImg = true;
          } else {
            this.hasImg = false;
          }
          switch (res.data.valid_time) {
            case null:
              this.valid_time = "长期有效";
              break;
            case 0:
              this.valid_time = "长期有效";
              break;
            case 1:
              this.valid_time = "一周";
              break;
            case 2:
              this.valid_time = "一个月";
              break;
            case 3:
              this.valid_time = "两个月";
              break;
            case 4:
              this.valid_time = "一年";
              break;
          }
          if (
            res.data.tid == 3 ||
            res.data.tid == 7 ||
            res.data.tid == 9 ||
            res.data.tid == 17
          ) {
            this.HouseShow = true;
          } else if (res.data.tid == 11 || res.data.tid == 15) {
            this.HouseAShow = true;
          } else if (res.data.tid == 1) {
            this.HouseBShow = true;
          } else if (res.data.tid == 5) {
            this.HouseCShow = true;
          } else if (res.data.tid == 13) {
            this.HouseDShow = true;
          }
          //房间配置
          if (res.data.bed) {
            this.bed.value = 1;
          } else {
            this.bed.value = 0;
          }
          if (res.data.wardrobe) {
            this.wardrobe.value = 1;
          } else {
            this.wardrobe.value = 0;
          }
          if (res.data.sofa) {
            this.sofa.value = 1;
          } else {
            this.sofa.value = 0;
          }
          if (res.data.tv) {
            this.tv.value = 1;
          } else {
            this.tv.value = 0;
          }
          if (res.data.refrigerator) {
            this.refrigerator.value = 1;
          } else {
            this.refrigerator.value = 0;
          }
          if (res.data.washing_machine) {
            this.washing_machine.value = 1;
          } else {
            this.washing_machine.value = 0;
          }
          if (res.data.air_conditioning) {
            this.air_conditioning.value = 1;
          } else {
            this.air_conditioning.value = 0;
          }
          if (res.data.hot_water) {
            this.hot_water.value = 1;
          } else {
            this.hot_water.value = 0;
          }
          if (res.data.wifi) {
            this.wifi.value = 1;
          } else {
            this.wifi.value = 0;
          }
          if (res.data.heating) {
            this.heating.value = 1;
          } else {
            this.heating.value = 0;
          }
          //身份
          if (res.data.identity == 1) {
            this.identity = "个人";
          } else {
            this.identity = "经纪人";
          }
          //出租形式
          switch (res.data.rental_form) {
            case 0:
              this.rental_form = "";
              break;
            case 1:
              this.rental_form = "整套";
              break;
            case 2:
              this.rental_form = "单间";
              break;
            case 3:
              this.rental_form = "床位";
              break;
          }
          //房型
          switch (res.data.room_type) {
            case 1:
              this.room_type = "1室0厅";
              break;
            case 2:
              this.room_type = "1室1厅";
              break;
            case 3:
              this.room_type = "2室1厅";
              break;
            case 4:
              this.room_type = "2室2厅";
              break;
            case 5:
              this.room_type = "3室1厅";
              break;
            case 6:
              this.room_type = "3室2厅";
              break;
            case 7:
              this.room_type = "4室及以上";
              break;
          }
          //装修
          switch (res.data.decoration) {
            case 1:
              this.decoration = "毛坯";
              break;
            case 2:
              this.decoration = "简装";
              break;
            case 3:
              this.decoration = "精装";
              break;
          }

          //////
        }
      });
    }
    //method end
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.page {
  background: #fff;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  .header {
    padding: 0.55rem 0.3rem;
    border-bottom: 1px solid #eee;
    h2 {
      font-weight: normal;
      font-size: 0.42rem;
      margin-bottom: 0.5rem;
    }
    .timeBox {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 0.34rem;
      height: 0.5rem;
      align-items: center;
      .iconfont {
        margin-right: 0.1rem;
      }
      .like {
        display: flex;
        align-items: center;
        .van-icon {
          font-size: 0.5rem;
          border: none;
          color: #ccc;
          margin-left: 0.05rem;
        }
        .van-checkbox__icon--checked .van-icon {
          background: transparent;
        }
      }
    }
  }
  .line {
    height: 0.2rem;
    background: #f5f5f5;
  }
  .body {
    padding: 0.8rem 0.3rem 0.6rem 0.3rem;
    li.fl {
      height: auto;
      overflow: hidden;
    }
    li {
      color: #9e9e9e;
      margin-bottom: 0.26rem;
      font-size: 0.38rem;
      .spanfl {
        float: left;
        line-height: 0.7rem;
      }
      .spanfr {
        margin-left: 1.8rem;
        line-height: 0.7rem;
      }
      span {
        margin-left: 0.2rem;
        color: #333;
        font-size: 0.38rem;
        a {
          color: red;
          font-style: normal;
          font-size: 0.4rem;
          vertical-align: -0.03rem;
          margin-right: 0.2rem;
        }
      }
      img {
        display: inline-block;
        width: 3.8rem;
        margin-right: 0.25rem;
      }
      .telphone {
        display: block;
        background: #567cf3;
        text-align: center;
        line-height: 0.8rem;
        width: 2.8rem;
        border-radius: 0.8rem;
        color: #fff;
      }
    }
  }
  .dr {
    background: #f5f5f5;
    color: #777;
    padding: 0.3rem 0.3rem;
    font-size: 0.38rem;
  }
  .detail {
    padding: 0.5rem 0.3rem;
    font-size: 0.38rem;
    line-height: .6rem;
  }
}
</style>